import React from "react";
import { SignUp } from "../components/Signin";

const SignupPage = () => {
  return (
    <div>
      <SignUp />
    </div>
  );
};

export default SignupPage;
