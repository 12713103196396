export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "International Payments",
  headline: "Unlimited Transactions with low fees",
  description:
    "Get access to our exclusive app that allows you to accept and send unlimited payments by crypto, credit card or local payment methods.",
  buttonLabel: "Get started",
  imgStart: true,
  img: require("../../images/svg-2.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: " Secure Access",
  headline: "Login to your account at any time",
  description:
    "We have you covered no matter where you are located. All you need is an internet connection and a phone or computer",
  buttonLabel: "Learn More",
  imgStart: false,
  img: require("../../images/svg-5.svg").default,
  alt: "PiggyBank",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "signup",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Get started now",
  headline: "Creating an account is extremely easy",
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: "Start Now",
  imgStart: true,
  img: require("../../images/svg-6.svg").default,
  alt: "Paper",
  dark: false,
  primary: false,
  darkText: true,
};
